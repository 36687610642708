import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { useMediaQuery } from '@mui/material'
import PlacesAutoComplete from 'react-places-autocomplete'

const useStyles = makeStyles(theme => ({
  textField: {
    border: 'solid 1.5px lightgrey',
    fontFamily: 'Raleway, sans-serif !important',
    fontWeight: '800',
    height: '60px',
    boxShadow: 'rgb(0 0 0 / 25%) 0px 2px 4px 0px',
    color: '#AE3B6A',
    borderRadius: '4px',
  },
  active: {
    backgroundColor: 'lightgrey',
    fontFamily: 'Raleway, sans-serif',
  },
  nonActive: {
    backgroundColor: 'white',
    fontFamily: 'Raleway, sans-serif',
  },
  typeAheadBox: {
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
    width: '100%',
    margin: 'auto',
    zIndex: 100,
  }
}))

const PlacesSearchBar = ({ select }) => {
  const classes = useStyles()
  const [address, setAddress] = useState('')
  const isSmallScreen = useMediaQuery('(max-width:960px)')
  
  const handleSelect = async (desc, id) => {
    select(id, desc)
    setAddress(desc)
  }
  
  return (
    <div>
      <PlacesAutoComplete
        value={address || ''}
        onChange={setAddress}
        onSelect={(desc, id) => handleSelect(desc, id)}
        styles={{width: '80%', height: '40px'}}
        variant="outlined"
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input className={classes.textField} style={{width: isSmallScreen ? '100%': '50%'}} {...getInputProps({autoFocus: false})}/>
            <div className={classes.typeAheadBox} style={{
              fontSize: isSmallScreen ? 'unset' : '50%',
              width: isSmallScreen ? 'unset' : '50%'
            }}>
              {loading ? <div>...loading</div> : null}
              {suggestions.map((suggestion, i) => (
                <div
                  key={i}
                  className={suggestion.active ? classes.active : classes.nonActive}
                  {...getSuggestionItemProps(suggestion)}
                >
                  {suggestion.description}
                </div>
                ))}
            </div>
          </div>
        )}
      </PlacesAutoComplete>
    </div>
  )
}

export default PlacesSearchBar