import React from 'react'
import { Outlet } from "react-router"
import logo from "../../assets/bbcoLogo.png"
import { Box, Container, CssBaseline } from "@mui/material"

const AuthLayout = ({ small }) => {
  return (
    <Container maxWidth="xs" sx={{mb: 2}}>
      <Box sx={{m: 1}}>
        <img src={logo} alt='logo' style={{maxWidth: small ? '175px' : '250px'}}/>
      </Box>
      <CssBaseline />
      <Outlet />
    </Container>
  )
}

export default AuthLayout