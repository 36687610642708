export const createListingBody = (listingState, owner) => {
  const details = listingState.listingDetails.result
  const { photoUrls } = listingState
  const findComponent = term => details.address_components.find(comp => comp.types.includes(term)).short_name
  return {
    uuid: owner.uuid,
    name: details.name,
    addressLine1: parseAddress(details.address_components),
    city: findComponent('locality'),
    state: findComponent('administrative_area_level_1'),
    zip: findComponent('postal_code'),
    geoLat: details.geometry.location.lat,
    geoLng: details.geometry.location.lng,
    websiteUrl: details.website,
    contactNumber1: details.formatted_phone_number,
    owners: owner.firstName ? `{${owner.firstName} ${owner.lastName}}`: null,
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    linkedInUrl: '',
    imageUrls: `{${photoUrls ? photoUrls.join(',') : ''}}`,
    categories: `{${details.types.join(',')}}`,
    story: '',
    productsAndService: `(array[${setObjectArray([])}]::json[])`,
    email: owner.email,
    placesObj: JSON.stringify(details)
  }
}

export const createDefaultListingBody = (businessFormData, owner) => {
  return {
    uuid: owner.uuid,
    name: businessFormData.name,
    addressLine1: businessFormData.address,
    city: businessFormData.city,
    state: businessFormData.state,
    zip: businessFormData.zip,
    owners: owner.firstName ? `{${owner.firstName} ${owner.lastName}}`: `{}`,
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    linkedInUrl: '',
    imageUrls: `{${[].join(',')}}`,
    categories: `{}`,
    story: '',
    productsAndService: `(array[${setObjectArray([])}]::json[])`,
    email: owner.email,
    placesObj: '',
    websiteUrl: '',
    geoLat: 0,
    geoLng: 0,
  }
}

export const createUserBody = attributes => {
  // google
  if (!attributes.sub) {
    attributes = attributes.signInUserSession.idToken.payload
  }
  return {
    email: attributes.email,
    firstName: attributes.given_name,
    lastName: attributes.family_name,
    mi: '',
    uuid: attributes.sub,
    verified: attributes.email_verified
  }
}

const setObjectArray = ps => ps.map(item => `'${JSON.stringify(item)}'`)

export const doFetch = async (url, method, body) => {
  try {
    const res = await fetch(url, { // eslint-disable-line no-undef
      method: method,
      headers: new Headers({ // eslint-disable-line no-undef
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }),
      ...body && { body: JSON.stringify(body) },
    })
    return res.json()
  } catch (err) {
    return {
      success: false,
      message: err.message
    }
  }
}

const parseAddress = (components) => {
  return components.reduce((acc, curr) => {
    if (curr.types.includes('street_number') || curr.types.includes('route')) {
      let space = ''
      if (curr.types.includes('route')) space = ' '
      acc += space + curr.short_name
    }
    return acc
  }, '')
}

export const validateEmail = email => {
  return email.includes('@') && email.includes('.')
}