import { useContext, useEffect, useCallback, useState } from 'react'
import { Auth } from '@aws-amplify/auth'
import { AppContext } from '../context/appContext'
import { createUserBody, doFetch } from "../util"
// going to change this up a bit. Since we are accessing the Auth library AND the AppContext it seems silly
// to access them both in every component and then implement them in different ways. Lets create a standard hook
// that returns functions that allow you to interact with the Auth library and the App Context in a standard way.

// The hook will return the current user if there is one or else will return something falsey. Regardless it will
// also return standard methods to login, logout, signup, and federated login. The only place the Auth library should be
// used is here in this hook.
const UseAuthUser = () => {
  const { currentUser, setCurrentUser } = useContext(AppContext)
  const [authLoading, setAuthLoading] = useState(false)

  // Access the user session on the client
  useEffect(() => {
    if (!currentUser) {
      setAuthLoading(true)
      Auth.currentAuthenticatedUser()
        .then(async u => {
          const body = createUserBody(u)
          await insertOrGetUser(body)
          setCurrentUser(body)
          setAuthLoading(false)
        })
        .catch(() => {
          setAuthLoading(false)
          setCurrentUser(null)
        })
    }
  }, [currentUser, setCurrentUser, setAuthLoading])

  const logout = useCallback(() => {
    // do other validation or logic
    return signOut(currentUser, setCurrentUser)
  }, [currentUser, setCurrentUser])

  const login = useCallback((email, password) => {
    // do other validation or logic
    return signIn(email, password, setCurrentUser)
  }, [setCurrentUser])

  const federatedLogin = useCallback(async (provider) => {
    // do other validation or logic
    return federatedSignIn(provider, setCurrentUser)
  }, [setCurrentUser])

  const register = useCallback((body) => {
    // do other validation or logic
    return signUp(body, setCurrentUser)
  }, [setCurrentUser])

  const confirmRegister = useCallback((email, code) => {
    // do other validation or logic
    return confirmSignUp(email, code)
  }, [setCurrentUser])

  const resendSignup = useCallback((email) => {
    // do other validation or logic
    return resendSignUp(email, setCurrentUser)
  }, [setCurrentUser])

  // const resendPassword

  return {
    currentUser,
    logout,
    login,
    register,
    federatedLogin,
    confirmRegister,
    resendSignup,
    authLoading
  }
}

// 2. All of these standard methods use the AppContext's state to run setters and getters. The functions themselves
// are stateless
const signIn = async (email, password, setCurrentUser) => {
  try {
    // login through cognito
    const currentUser = await Auth.signIn(email, password)
    const body = createUserBody(currentUser)
    await insertOrGetUser(body)
    // set current user
    setCurrentUser(body)
  } catch (err) {
    setCurrentUser(null)
    return err.message
  }
}

const federatedSignIn = async (provider, setCurrentUser) => {
  try {
    // login through cognito
    await Auth.federatedSignIn(provider)
    // force use effect to run after federated login
    setCurrentUser(null)
  } catch (err) {
    setCurrentUser(null)
    return err.message
  }
}

const signOut = async (user, setCurrentUser) => {
  try {
    await Auth.signOut()
    setCurrentUser(null)
  } catch (err) {
    setCurrentUser(user)
    return err.message
  }
}

const signUp = async (body, setCurrentUser) => {
  try {
    const { email, password, firstName, lastName } = body
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,          // optional
        family_name: lastName,
        given_name: firstName
      }
    })
    return false
  } catch (err) {
    setCurrentUser(null)
    return err.message
  }
}

const resendSignUp = async (email, setCurrentUser) => {
  try {
    await Auth.resendSignUp(email)
  } catch (err) {
    return err.message
  }
}

const confirmSignUp = async (email, code) => {
  try {
    await Auth.confirmSignUp(email, code)
  } catch (err) {
    return err.message
  }
}

const insertOrGetUser = body => {
  const url = `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/user`
  return doFetch(url, 'POST', body)
}

export default UseAuthUser