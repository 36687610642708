import React, { useEffect } from 'react'
import { useAuthUser } from "../../hooks"
import HomeLayout from "./home"
import AuthLayout from "./auth"
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from "@mui/material"

const MainLayout = () => {
  const { currentUser, authLoading } = useAuthUser()
  const navigate = useNavigate()
  const isSmallScreen = useMediaQuery('(max-width:960px)')

  useEffect(() => {
    const route = currentUser ? '/home' : '/sign-up'
    navigate(route, { replace: true })
  }, [currentUser])

  return (
    <>
      {!authLoading && (
        <>
          {
            currentUser
              ? <HomeLayout small={isSmallScreen} />
              : <AuthLayout small={isSmallScreen} />
          }
        </>
      )}
    </>
  )
}

export default MainLayout