import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button
} from '@mui/material'
import { useLocation, useNavigate } from "react-router-dom"
import { useAuthUser } from "../hooks"
import { ArrowBack } from '@mui/icons-material'
import { Helmet } from "react-helmet"

const initialFormState = {
  email: '',
  authCode: ''
}

const Verification = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { confirmRegister, resendSignup, logout, login } = useAuthUser()
  const [formState, setFormState] = useState(initialFormState)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (location.state) {
      setFormState(() => ({
        ...formState,
        email: location.state.email,
        password: location.state.password
      }))
    }
  }, [])

  const onChange = e => {
    setFormState(() => ({
      ...formState,
      [e.target.name]: e.target.value
    }))
  }

  const handleResend = async e => {
    e.preventDefault()
    if (formState.email === '') {
      setError('Email cannot be blank')
      return
    }

    await resendSignup(formState.email)
    setError(`A new verification code has been sent to ${formState.email}`)
  }

  const formIsInvalid = () => {
    const { email, authCode } = formState
    if (email === '') return 'Email cannot be blank'
    if (authCode === '') return 'Verification Code cannot be blank'
    return false
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const isInvalid = formIsInvalid()
    if (isInvalid) {
      setError(isInvalid)
      return
    }

    setError(null)
    // confirm
    let errorMessage = await confirmRegister(formState.email, formState.authCode)
    if (errorMessage) {
      setError("There was a problem confirming your sign up. Please try again.")
      return
    }

    // login user if we have their password already, else send them to sign in again
    if (!!formState.password) {
      errorMessage = await login(formState.email, formState.password)
      // if there is no error let's navigate them home
      if (!errorMessage) {
        navigate('/home', { replace: true })
        return
      }
    }

    // navigate sign-in
    navigate('/sign-in', { replace: true })
  }

  const handleBackButtonClick = async e =>  {
    e.preventDefault()
    await logout()
    navigate('/sign-up', { replace: true })
  }

  return (
    <Box>
      <Helmet>
        <title>BBCO | Verify</title>
        <meta name="description" content="bbco verification page" />
      </Helmet>
      <Box sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        p: 2,
        cursor: 'pointer'
      }}
           onClick={handleBackButtonClick}
      >
        <ArrowBack sx={{width: '50%'}}/>
      </Box>
      <Typography
        component="h1"
        variant="h5"
      >
        Join The Black Business Company
      </Typography>
      <Typography variant="body1" sx={{mt: 1, mb: 1}}>
        Please check your inbox for a verification and enter it below.
      </Typography>
      { error && (
        <Typography variant="body1" sx={{color: '#AE3B6A', pb: 2}}>
          {error}
        </Typography>
      )}
      <form noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              value={formState.email}
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="authCode"
              label="Verification Code"
              name="authCode"
              autoComplete="confirmation code"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Verify My Account
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="text"
              color="primary"
              onClick={handleResend}
            >
              Resend Verification Code
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default Verification