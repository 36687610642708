import * as React from "react"
import './App.css'
import { useRoutes } from 'react-router-dom'
import Amplify from 'aws-amplify'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from "@mui/material/CssBaseline"
import theme from './theme'
import awsConfig from './aws-exports'
import Footer from "./components/footer"
import { AppProvider } from "./context/appContext"
import routes from "./routes"

// check that which environment we are in
const isLocalhost = window.location.hostname === "localhost" || window.location.hostname.includes("develop")

// split our redirect uris
const [ localRedirectSignIn, productionRedirectSignIn ] = awsConfig.oauth.redirectSignIn.split(",")
const [ localRedirectSignOut, productionRedirectSignOut ] = awsConfig.oauth.redirectSignOut.split(",")

// dynamically set config
const amplifyConfig = {
  ...awsConfig,
  aws_user_pools_web_client_id: isLocalhost ? '3q9iis7r4s98jjcstb6gadu90b' : '6jjavo9j7ui9t42m4ohi3lu2bs',
  aws_user_pools_id: isLocalhost ? 'us-east-1_ekZUHwENt' : 'us-east-1_WAJ47dhQv',
  oauth: {
    ...awsConfig.oauth,
    domain: isLocalhost
      ? awsConfig.oauth.domain.replace(/prod/g, 'dev')
      : awsConfig.oauth.domain.replace(/dev/g, 'prod'),
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  }
}

Amplify.configure(amplifyConfig)
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <AppProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            { useRoutes(routes()) }
          </ThemeProvider>
        </AppProvider>
      </header>
      <Footer />
    </div>
  )
}

export default App
