import React, { useState } from 'react'
import {
  Box,
  Typography,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button
} from "@mui/material"
import GoogleButton from "react-google-button"
import Spinner from "../components/spinner"
import { useNavigate } from "react-router-dom"
import { useAuthUser } from "../hooks"
import {validateEmail} from "../util"
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth"
import { Helmet } from "react-helmet"

const initialFormState = {
  firstName: '',
  lastName: '',
  password: '',
  email: ''
}

const AlreadyHaveAnAccount = ({ signIn }) => {
  return (
    <Button
      style={{
        textDecoration: 'underline',
        textTransform: 'none',
        cursor: 'pointer',
        color: '#AE3B6A'
      }}
      onClick={() => signIn()}
    >
      <Typography variant='body1'>
        already have an account?
      </Typography>
    </Button>
  )
}

const SignUp = () => {
  const navigate = useNavigate()
  const { register, federatedLogin } = useAuthUser()
  const [formState, setFormState] = useState(initialFormState)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const onChange = e => {
    e.persist()
    setFormState(() => ({
      ...formState,
      [e.target.name]: e.target.value
    }))
  }

  const handleGoToSignIn = () => navigate('/sign-in', { replace: true })

  const handleGoogleButtonClick = async e => {
    e.preventDefault()

    // returns an error or nil
    return federatedLogin({ provider: CognitoHostedUIIdentityProvider.Google })
  }

  const formIsNotValid = () => {
    const {firstName, lastName, email, password} = formState
    if (!validateEmail(email)) return 'Please enter a valid email'
    if (firstName.trim() === '') return 'First Name cannot be blank'
    if (lastName.trim() === '') return 'Last Name cannot be blank'
    if (password.trim() === '' || password.length < 6) return 'Password must be at least 6 characters'
    return false
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const notValid = formIsNotValid()
    if (notValid) {
      setLoading(false)
      setError(notValid)
      return
    }

    setError(null)
    const errorMessage = await register(formState)
    if (errorMessage) {
      setError('There was an issue registering your account. Please try again later.')
      return
    }
    setLoading(false)
    navigate('/verification', { replace: true, state: { email: formState.email, password: formState.password } })
  }

  return (
    <Box>
      <Helmet>
        <title>BBCO | Sign Up</title>
        <meta name="description" content="bbco sign up page" />
      </Helmet>
      <Typography
        component="h1"
        variant="h5"
      >
        Join The Black Business Company
      </Typography>
      <Typography variant="body1" sx={{mt: 1, mb: 1}}>
        Create your free account now.
      </Typography>
      { error && (
        <Typography variant="body1" sx={{color: '#AE3B6A', pb: 2}}>
          {error}
        </Typography>
      )}
      <form  noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="fname"
              name="firstName"
              variant="outlined"
              required
              fullWidth
              id="firstName"
              label="First Name"
              autoFocus
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lname"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Create Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <AlreadyHaveAnAccount signIn={handleGoToSignIn}/>
            </Typography>
            <FormControlLabel
              control={<Checkbox value="allowExtraEmails" color="primary" />}
              label="Get BBCO news & updates"
            />
          </Grid>
          <Grid item xs={12}>
            <GoogleButton
              type="light" // can be light or dark
              onClick={handleGoogleButtonClick}
              style={{width: '100%'}}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              style={{backgroundColor: '#42A9B3'}}
            >
              {loading
                ? <Spinner color={'#ffffff'}/>
                : (<Typography variant='body1'>Sign Up With Email & Password</Typography>)
              }
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default SignUp