import React, { createContext, useState } from 'react'
import { useMediaQuery } from "@mui/material"

const AppContext = createContext({})

const AppProvider = ({ children }) => {
  const isSmallScreen = useMediaQuery('(max-width:960px)')
  const [currentUser, setCurrentUser] = useState(null)
  const [isMobile, setIsMobile] = useState(isSmallScreen)
  return (
    <AppContext.Provider
      value={{
        isMobile,
        setIsMobile,
        currentUser,
        setCurrentUser
      }}>{children}
    </AppContext.Provider>
  )
}

export { AppProvider, AppContext }
