import React, {useEffect, useState} from 'react'
import {
  Box,
  useMediaQuery,
  Button,
  ButtonGroup,
  Typography,
  TextField
} from "@mui/material"
import { doFetch } from "../util"
import { useAuthUser } from "../hooks"
import Listing from "../components/listing"
import thankYou from "../assets/ThankYouVector.png"
import Spinner from "../components/spinner"
import { Helmet } from "react-helmet"

const Home = () => {
  const { currentUser, logout } = useAuthUser()
  const isSmallScreen = useMediaQuery('(max-width:960px)')
  const [isBlackOwner, setIsBlackOwned] = useState(false)
  const [step, setStep] = useState(0)
  const [nomineeEmail, setNomineeEmail] = useState('')
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [loading, setLoading] = useState(false)
  const [nominationSent, setNominationSent] = useState(false)

  useEffect(() => {
    if (currentUser) {
      const url = `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/nominee?uuid=${currentUser.uuid}`
      doFetch(url, 'GET', null)
        .then(res => {
          if (res.length > 0) {
            setNomineeEmail(res[0].nominee_email)
            setNominationSent(true)
          }
        })
    }
  }, [])

  const handleLogout = e => {
    e.preventDefault()
    return logout()
  }

  const handleStepOne = blackOwned => {
    setIsBlackOwned(blackOwned)
    setStep(step + 1)
  }

  const finish = () => {
    setStep(2)
  }

  const onChange = e => setNomineeEmail(e.target.value)
  const onSubmit = async e => {
    e.preventDefault()
    if (nomineeEmail.includes('@') && nomineeEmail.includes('.')) {
      setInvalidEmail(false)
      setLoading(true)
      // make call to send referral email

      const url = `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/nominee`
      await doFetch(url, 'POST', {email: nomineeEmail, uuid: currentUser.uuid})
      setLoading(false)
      setInvalidEmail(false)
      setNominationSent(true)
    } else {
      setLoading(false)
      setInvalidEmail(true)
    }
  }

  return (
    <>
      <Helmet>
        <title>BBCO | Home</title>
        <meta name="description" content="bbco home page" />
      </Helmet>
      {
        step === 0 && (
          <>
            <Typography
              component='h1'
              variant='h5'
            >
              Are you a Black business owner?
            </Typography>
            <br />
            <ButtonGroup>
              <Button variant='contained' color='primary' onClick={() => handleStepOne(true)}>Yes!</Button>
              <Button onClick={() => handleStepOne(false)}>No</Button>
            </ButtonGroup>
          </>
        )
      }
      {
        step === 1 && (isBlackOwner
            ? (
              <Listing currentUser={currentUser} finish={finish} back={() => setStep(0)} />
            )
            :
            (finish())
        )
      }
      {
        step === 2 && (
        <Box sx={{width: '80%', margin: 'auto'}}>
          <img
            style={{width: isSmallScreen ? '80%' : '20%'}}
            src={thankYou} alt='thank you'
          />
          <Typography
            align="center"
            component="h1"
            variant="h4"
          >
            You're savvy and we like it.
          </Typography>
          <Typography
            align="center"
            variant="body1"
          >
            You're on the waitlist to access the BBCO platform! Hooray!
          </Typography>
          <Typography
            align="center"
            variant="body1"
          >
            <Box>Keep an eye on your email inbox. We'll alert you when it's your turn to access the website.</Box>
          </Typography>
          <br />
          {
            !nominationSent
              ? (
                <>
                  <Typography
                    align="center"
                    variant="body1"
                  >
                    Know a Black business owner who'd make a great fit? Nominate them and be credited on their listing <i style={{color: '#AE3B6A', fontWeight: 'bold'}}>forever.</i>
                  </Typography>
                  {invalidEmail && (
                    <Typography
                      align="center"
                      variant="body1"
                      style={{color: '#AE3B6A', fontWeight: 'bold'}}
                    >
                      Email is invalid. Please try again
                    </Typography>
                  )}
                  <TextField
                    style={{ width: '240px', marginBottom: '15px', marginTop: '15px'}}
                    variant="outlined"
                    required
                    id="email"
                    label="Nominee Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={onChange}
                  />
                  <Button variant="contained" color="primary" style={{ width: '200px', display: 'block', margin: 'auto'}} onClick={onSubmit}>
                    {loading
                      ? <Spinner color={'#ffffff'}/>
                      : (<Typography variant='body1'>Nominate!</Typography>)
                    }
                  </Button>
                </>
              )
              : (
                <>
                  <Typography
                    align="center"
                    variant="body1"
                  >
                    Nominated: <strong style={{textDecoration: 'underline'}}>{nomineeEmail}</strong>
                  </Typography>
                </>
              )
          }
          <Button onClick={handleLogout}>Sign Out</Button>
        </Box>
      )}
    </>
  )
}

export default Home