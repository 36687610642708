import React, { useState } from 'react'
import {
  Box,
  Button,
  TextField,
  Grid,
  Typography
} from "@mui/material"
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth"
import GoogleButton from "react-google-button"
import { useNavigate } from "react-router-dom"
import { useAuthUser } from "../hooks"
import { Helmet } from "react-helmet"

const CreateAccount = ({ signUp }) => {
  return (
    <Button
      variant="text"
      style={{
        textDecoration: 'underline',
        cursor: 'pointer'
      }}
      onClick={() => signUp()}
    >
      Don't have an account?
    </Button>
  )
}

const initialFormState = {
  email: '',
  password: ''
}

const SignIn = () => {
  const navigate = useNavigate()
  const { login, federatedLogin } = useAuthUser()
  const [error, setError] = useState(null)
  const [formState, setFormState] = useState(initialFormState)

  const handleGoogleButtonClick = e => {
    e.preventDefault()
    return federatedLogin({ provider: CognitoHostedUIIdentityProvider.Google })
  }

  const onChange = e => {
    setFormState(() => ({
      ...formState,
      [e.target.name]: e.target.value
    }))
  }

  const handleGoToSignUp = () => navigate('/sign-up', { replace: true })

  const formIsInvalid = () => {
    const { email, password } = formState
    if (email === "") return "Email cannot be blank"
    if (password === "") return "Password cannot be blank"
    return false
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const isInvalid = formIsInvalid()
    if (isInvalid) {
      setError(isInvalid)
      return
    }

    setError(null)
    const errorMessage = await login(formState.email, formState.password)
    if (errorMessage) handleError(errorMessage)
  }

  const handleError = message => {
    if (message.toLowerCase() === 'user is not confirmed.') {
      navigate('/verification', { replace: true, state: { email: formState.email }})
    }
    setError(message)
  }

  return (
    <Box>
      <Helmet>
        <title>BBCO | Sign In</title>
        <meta name="description" content="bbco sign in page" />
      </Helmet>
      <Typography
        component="h1"
        variant="h5"
      >
        Sign In
      </Typography>
      <Typography variant="body1" sx={{mt: 1, mb: 1}}>
        Please enter your email and password
      </Typography>
      { error && (
        <Typography variant="body1" style={{color: '#AE3B6A'}}>
          {error}
        </Typography>
      )}
      <form noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="password"
              label="Password"
              type="password"
              name="password"
              autoComplete="password"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <CreateAccount signUp={handleGoToSignUp}/>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Sign In
            </Button>
          </Grid>
          <Grid item xs={12}>
            <GoogleButton
              type="light" // can be light or dark
              onClick={handleGoogleButtonClick}
              style={{width: '100%'}}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default SignIn