import { createTheme, colors } from '@mui/material';

const theme = createTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white,
      textTransform: 'none'
    },
    primary: {
      contrastText: '#ffffff',
      main: '#AE3B6A',
      textTransform: 'lowercase'
    },
    text: {
      primary: '#AE3B6A',
      secondary: '#6b778c',
      textTransform: 'none'
    }
  },
  typography: {
    fontFamily: 'Raleway, sans-serif',
    body1: {
      fontWeight: 'bold',
    },
    h5: {
      fontFamily: 'Playfair Display',
      fontWeight: 'bold',
    },
    h4: {
      fontFamily: 'Playfair Display',
      fontWeight: 'bold',
    },
    button: {
      textTransform: 'none',
      fontWeight: 'bold'
    }
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          boxShadow: 'rgb(0 0 0 / 25%) 0px 2px 4px 0px',
          backgroundColor: 'white',
          borderRadius: '4px'
        }
      }
    }
  }
})

export default theme