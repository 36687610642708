import React from 'react'
import { CircularProgress } from "@mui/material"
import { withStyles } from '@mui/styles'

const styles = {
  colorPrimary: {
    backgroundColor: '#FD8907',
  },
  root: {
    marginLeft: 5,
    color: '#ffffff !important'
  }
}

const Spinner = withStyles(styles)(({ color }) => (
  <CircularProgress
    size={20}
    style={{color}}
  />
))

export default Spinner