import React from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Typography } from '@mui/material'

const useStyles = makeStyles(theme => ({
  component: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '8px',
    height: '20px',
    width: '20px'
  },
  text: {
    color: 'white'
  }
}))

const SmGroup = ({ text, icon, url }) => {
  const classes = useStyles()
  return (
    <a style={{textDecoration: 'none', width: '100%'}} href={url}>
      <Box component='div' className={classes.component}>
        <img src={icon} className={classes.icon} alt={text}/>
        <Typography
          sx={{color: '#FFFFFF'}}
          variant='body1'
          className={classes.text}
        >
          {text}
        </Typography>
      </Box>
    </a>
  )
}

export default SmGroup