import React from "react"
import { Navigate } from "react-router"
import SignUp from "./pages/SignUp"
import SignIn from "./pages/SignIn"
import NotFound from "./pages/NotFound"
import MainLayout from "./components/layout/main"
import Home from "./pages/Home"
import Verification from "./pages/Verification"


const routes = () => [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'sign-up', element: <SignUp /> },
      { path: 'sign-in', element: <SignIn /> },
      { path: 'verification', element: <Verification /> },
      { path: 'home', element: <Home /> },
      { path: '404', element: <NotFound /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
]

export default routes