import React from 'react'
import { Helmet } from "react-helmet"

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>BBCO | Not Found</title>
        <meta name="description" content="bbco not found page" />
      </Helmet>
      <h2>Page Not Found</h2>
    </>
  )
}

export default NotFound