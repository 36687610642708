import React from 'react'
import { Outlet } from "react-router"
import { Container, CssBaseline, Box } from "@mui/material"
import logo from "../../assets/bbcoLogo.png"

const HomeLayout = ({ small }) => {
  return (
    <Container maxWidth="lg" sx={{mb: 2}}>
      <Box sx={{m: 4}}>
        <img src={logo} alt='logo' style={{maxWidth: small ? '175px' : '250px'}}/>
      </Box>
      <CssBaseline />
      <Outlet />
    </Container>
  )
}

export default HomeLayout