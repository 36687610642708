import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Grid, Typography, Box, useMediaQuery, TextField } from "@mui/material"
import { ArrowBack } from '@mui/icons-material'
import Carousel from 'react-material-ui-carousel'
import PlacesSearchBar from "./placesSearchBar"
import { createDefaultListingBody, createListingBody, doFetch } from '../../util'
import location from '../../assets/location.png'
import Spinner from "../spinner"

const GMB = ({ handleGmb }) => (
  <>
    <Typography
      component="h1"
      variant="h5"
    >
      Before we add your business..
    </Typography>
    <br />
    <Typography
      component="h6"
      variant="body1"
    >
      We want to kow how to register it! Do you already have a <a href="https://www.google.com/business/">Google My Business</a> account?
    </Typography>
    <br />
    <ButtonGroup>
      <Button variant='contained' color='primary' onClick={() => handleGmb(true)}>I already have a Google My Business account</Button>
      <Button onClick={() => handleGmb(false)}>I don't have that account</Button>
    </ButtonGroup>
  </>
)

const GetPlaces = ({ handleSelect, urls }) => (
  <>
    <PlacesSearchBar select={handleSelect} />
    {urls.length > 0 && (
      <img src={urls[0]} alt='listing' />
    )}
  </>
)

const SearchForBusiness = ({ handleSelect, isMobile }) => (
  <>
    <Typography
      component='h1'
      variant='h5'
    >
      Search for your business!
    </Typography>
    <br />
    <GetPlaces handleSelect={handleSelect} urls={initialState.photoUrls} />
    <br />
    <Button
      type="submit"
      variant="contained"
      color="primary"
      style={{ backgroundColor: '#AE3B6A', width: isMobile ? '100%' : '20%', margin: 'auto' }}
      onClick={handleSelect}
    >
      Select
    </Button>
  </>
)

const ConfirmPlace = ({listingState, handleConfirm, small, loading, error}) => (
  <Grid
    container
    spacing={3}
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: '7%',
      flexDirection: small ? 'column': 'row'
    }}
  >
    <Grid item lg={4} sm={12}
          sx={{
            height: small ? '250px' : '450px'
          }}
    >
      {listingState.photoUrls ?
          (
            <Carousel>
              {listingState.photoUrls.map((url, i) => (
                <img
                  key={i}
                  src={url}
                  alt='listingImage'
                  style={{
                    ...small && {
                      width: '100%',
                      maxHeight: '205px'
                    }
                  }}
                />
              ))}
            </Carousel>
          )
        : (<img src={location} alt='default' style={{width: '60%'}}/>)

      }
    </Grid>
    <Grid item lg={4} sm={12}>
      <Typography
        component="h2"
        variant="h4"
      >
        {listingState.listingDetails.result.name}
      </Typography>
      <br />
      <Typography
        component="h6"
        variant="body1"
      >
        Address: {listingState.listingDetails.result.vicinity}
      </Typography>
      <Typography
        component="h6"
        variant="body1"
      >
        Website: {listingState.listingDetails.result.website}
      </Typography>
      <Typography
        component="h6"
        variant="body1"
      >
        Phone: {listingState.listingDetails.result.formatted_phone_number}
      </Typography>
      <br />
      {error && <Typography variant='body1' style={{color: '#AE3B6A'}}>There was an issue adding your business. Please try again later</Typography>}
      <ButtonGroup>
        <Button variant='contained' color='primary' onClick={() => handleConfirm(false)}>Search again</Button>
        <Button onClick={() => handleConfirm(true)}>
          {/*That's my business!*/}
          {loading
            ? <Spinner color={'#AE3B6A'}/>
            : (<Typography variant='body1'>That's my business!</Typography>)
          }
        </Button>
      </ButtonGroup>
    </Grid>
  </Grid>
)

const BusinessForm = ({ onChange, handleSubmit, isMobile }) => (
  <>
    <Typography
      component='h2'
      variant='h5'
    >
      Tell us about your business
    </Typography>
    <br/>
    <form style={{width: isMobile ? '100%' : '30%', margin: 'auto'}} noValidate>
      <Grid container spacing={2}>
        <Grid item lg={12} sm={12} xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            name="name"
            label="Business Name"
            id="name"
            autoComplete="businessName"
            onChange={onChange}
          />
        </Grid>
        <Grid item lg={12} sm={12} xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            name="address"
            label="Address"
            id="address"
            autoComplete="businessAddress"
            onChange={onChange}
          />
        </Grid>
        <Grid item lg={12} sm={12} xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            name="city"
            label="City"
            id="city"
            autoComplete="businessCity"
            onChange={onChange}
          />
        </Grid>
        <Grid item lg={6} sm={12} xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            name="state"
            label="State"
            id="state"
            autoComplete="businessState"
            onChange={onChange}
          />
        </Grid>
        <Grid item lg={6} sm={12} xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            name="zip"
            label="Zip"
            id="zip"
            autoComplete="businessZip"
            onChange={onChange}
          />
        </Grid>
        <Grid item lg={12} sm={12} xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  </>
)

const initialState = {
  hasGmb: false,
  listingDetails: null,
  photoUrls: [],
  businessForm: {}
}

const Listing = ({ currentUser, finish, back }) => {
  const [step, setStep] = useState("gmb")
  const [listingState, setListingState] = useState(initialState)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const isSmallScreen = useMediaQuery('(max-width:960px)')

  useEffect(() => {
    if (listingState.listingDetails) {
      setStep('confirmPlace')
    }
  }, [listingState.listingDetails])

  const handleGmb = (hasGmb) => {
      setListingState({
        ...listingState,
        hasGmb
      })
    setStep(hasGmb ? "getPlaces" : "fillForm")
  }

  const handleBackButtonClick = e => {
    if (step !== 'gmb') {
      setStep("gmb")
      return
    }
    back()
  }

  const onChange = e => {
    setListingState({
      ...listingState,
      businessForm: {
        ...listingState.businessForm,
        [e.target.name]: e.target.value
      }
    })
  }

  const handleSelect = async (placeId, address) => {
    if (placeId) {
      // get the listing
      const { data } = await searchListingDetails(placeId)

      // get the photos
      let photoUrls = null
      if (data.result.photos) {
        photoUrls = data.result.photos.map(photo => getImage(photo.photo_reference))
      }
      setListingState({
        ...listingState,
        listingDetails: data,
        photoUrls
      })
    }
  }

  const searchListingDetails = async (placeId) => {
    const url = `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/map?placeId=${placeId}`
    return doFetch(url, 'GET')
  }

  const getImage = ref => {
    return `${process.env.REACT_APP_GOOGLE_IMAGES_BASE_URL}${ref}&key=${process.env.REACT_APP_API_KEY}`
  }

  const handleConfirm = async isCorrect => {
    if (!isCorrect) {
      setStep('getPlaces')
      return
    }

    // add business to database!
    setLoading(true)
    const body = createListingBody(listingState, currentUser)
    const url = `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/listing`
    const res = await doFetch(url, 'POST', body)
    setLoading(false)

    // finish flow
    setError(!!res.message)
    if (!res.message) {
      finish()
    }
  }

  // TODO: validate this data before submitting and include error state
  const handleSubmit = async (e) => {
    e.preventDefault()
    const body = createDefaultListingBody(listingState.businessForm, currentUser)
    const url = `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/listing`
    const res = await doFetch(url, 'POST', body)

    // finish flow
    if (!res.message) {
      finish()
    }
  }

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      // width: '100%',
      margin: 'auto'
    }}>
      <Box sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        p: 2,
        cursor: 'pointer'
      }}
           onClick={handleBackButtonClick}
      >
        <ArrowBack />
      </Box>
      { step === 'gmb' && (
        <Grid container spacing={3}>
          <Grid item lg={12}>
            <GMB handleGmb={handleGmb} />
          </Grid>
        </Grid>
      )}
      { step === 'getPlaces' && (
        <Grid container spacing={3}>
          <Grid item lg={12} xs={12}>
            <SearchForBusiness handleSelect={handleSelect} isMobile={isSmallScreen}/>
          </Grid>
        </Grid>
      )}
      { step === 'fillForm' && (
        <Grid container spacing={3}>
          <Grid item lg={12}>
            <BusinessForm onChange={onChange} handleSubmit={handleSubmit} isMobile={isSmallScreen}/>
          </Grid>
        </Grid>
      )}
      { step === 'confirmPlace' && (
        <Grid container spacing={3}>
          <Grid item lg={12}>
            <ConfirmPlace
              listingState={listingState}
              handleConfirm={handleConfirm}
              small={isSmallScreen}
              loading={loading}
              error={error}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default Listing