import React from 'react'
import { render, hydrate } from 'react-dom'
import { BrowserRouter } from "react-router-dom"
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const root = document.getElementById('root')
const BBCOApp = () => (
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
)

if (root.hasChildNodes()) {
  hydrate(<BBCOApp />, root)
} else {
  render(<BBCOApp />, root)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
