import React from 'react'
import {
  Typography,
  Box,
  useMediaQuery,
  Grid,
  Hidden
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import SmGroup from './footerSmGroup'
import FacebookIcon from '../../assets/Facebook.svg'
import TwitterIcon from '../../assets/Twitter.svg'
import InstagramIcon from '../../assets/Instagram.svg'

const useStyles = makeStyles(theme => ({
  footerComponent: {
    background: '#0B111C',
    color: '#ECF0FF',
    padding: '2% 0% 1% 0%',
  },
  container: {
    margin: 'auto',
    justifyContent: 'space-around',
    display: 'flex'
  },
  siteTitle: {
    flex: 1,
    fontFamily: 'Playfair Display, serif',
    fontWeight: '800',
    fontSize: '25px'
  },
  siteTitleSmall: {
    fontFamily: 'Playfair Display, serif',
    fontSize: '25px',
  },
  column: {
    flex: 1,
    width: '80%'
  },
  header: {
    fontWeight: '800',
    flex: 1,
    fontSize: '100%'
  },
  detail: {
    display: 'block'
  },
  copyright: {
    color: '#C6CADB'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  smContainer: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  autechLink: {
    textDecoration: 'underline',
    color: 'white'
  }
}))

const HiddenBlock = ({ small }) => (
  <>
    {
      small && <br />
    }
  </>
)

const Footer = () => {
  const classes = useStyles()
  const isSmallScreen = useMediaQuery('(max-width:960px)')
  const getText = text => isSmallScreen ? '' : text

  const getLink = text => {
    switch(text) {
      case 'Facebook':
        return 'https://www.facebook.com/theblackbusinesscompany'
      case 'Twitter':
        return 'https://twitter.com/theblackbizco'
      case 'Instagram':
        return 'http://instagram.com/theblackbusinesscompany'
      default:
        return null
    }
  }

  return (
    <Box component='div' className={classes.footerComponent}>
      <HiddenBlock small={isSmallScreen}/>
      <Box component={Grid} container style={{ width: isSmallScreen ? '90%' : '80%' }} className={classes.container}>
        <Box component={Grid} item md={6} sm={12} xs={12} style={{alignItems: 'left'}}>
          <Typography
            component="h2"
            variant="h4"
            color="inherit"
            align={ isSmallScreen ? "center" : "left" }
            // noWrap
            className={isSmallScreen ? classes.siteTitleSmall : classes.siteTitle}
          >
            The Black Business Company
          </Typography>
          <Typography
            variant="body1"
            color="inherit"
            align={isSmallScreen ? "center" : "left"}
            noWrap
          >
            Powered by <a href='https://autech.io' className={classes.autechLink}>AuTech LLC</a>
          </Typography>
          <Typography
            variant="body1"
            color="inherit"
            align={isSmallScreen ? "center" : "left"}
            // noWrap
          >
            Copyright © {new Date().getFullYear()} The Black Business Company. All Rights Reserved.
          </Typography>
        </Box>
        <Box component={Grid} item md={2} sm={12} xs={12} className={isSmallScreen ? classes.row : ''}>
          <Hidden only={['sm', 'xs']}>
            <Typography variant="h3" align="left" noWrap className={classes.header}>
              SOCIAL
            </Typography>
          </Hidden>
          <Box component='div' className={classes.smContainer}>
            <SmGroup text={getText('Facebook')} url={getLink('Facebook')} icon={FacebookIcon}/>
          </Box>
          <Box component='div' className={classes.smContainer}>
            <SmGroup text={getText('Twitter')} url={getLink('Twitter')} icon={TwitterIcon}/>
          </Box>
          <Box component='div' className={classes.smContainer}>
            <SmGroup text={getText('Instagram')} url={getLink('Instagram')} icon={InstagramIcon}/>
          </Box>
        </Box>
      </Box>
      <HiddenBlock small={isSmallScreen}/>
    </Box>
  )
}

export default Footer
